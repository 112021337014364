.chat-page {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  & .dialogs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    width: 30%;
    max-width: 20rem;
  }
  & .messages {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    padding: 1rem;
    & .message-line {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
    }
    & .content-main {
      a {
        text-decoration: none;
        border-bottom: 1px solid var(--color);
      }
    }
  }
  & .chat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & .dialog-history {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & .dialog-link {
    padding: 0.5rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
  }
  & .messages-actions {
    display: flex;
    gap: 1rem;
    & textarea {
      flex: 1;
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      font-size: 1rem;
    }
  }
  & .content-from-user {
    display: flex;
    gap: 1rem;
    & .content-main {
      background: #f0f0f0;
      padding: 0 1rem;
      border-radius: 0.5rem;
    }
  }
  & .content-from-character {
    display: flex;
    gap: 1rem;
    & .content-main {
      border: 1px solid #eee;
      padding: 0 1rem;
      border-radius: 0.5rem;
    }
  }
  & .message-right {
    display: flex;
    flex-direction: row-reverse;
    & .message-line {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  & .avatar-line img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
}

.on-mobile {
  display: none;
}

.on-desktop {
  display: block;
}

.dialog-history-is-active {
  display: none;
}

@media only screen and (max-width: 850px),
  @media only screen and (max-width: 1400px) and (-webkit-device-pixel-ratio: 3),
  @media only screen and (max-width: 1000px) and (-webkit-device-pixel-ratio: 2) {
  .chat-page {
    flex-direction: column;
    & .dialogs {
      width: 100%;
      max-width: 100%;
      padding: 0 1rem;
      gap: 0;
    }
    & .dialog-history {
      display: none;
    }
    & .dialog-history-is-active {
      display: flex;
      margin-top: 1rem;
    }
    & .messages {
      padding: 0;
      & .message-line {
        display: flex;
        gap: 0;
        flex-direction: column;
        align-items: flex-start;
        & .avatar-line img {
          margin-left: 0.5rem;
          position: relative;
        }
        & .content-main {
          width: 100%;
          border-radius: 0;
        }
      }
      & .message-right {
        & .message-line {
          align-items: flex-end;
          & .avatar-line img {
            margin-right: 0.5rem;
          }
        }
      }
    }
    & .messages-actions {
      & textarea {
        padding: 0.5rem;
        border-radius: 0;
      }
    }
  }
  .on-mobile {
    display: inline-block;
  }
  .on-desktop {
    display: none;
  }
}
