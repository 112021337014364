.nav-header {
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex: 0;
  width: 100%;
  & .logo-link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }
}
.top-menu {
  display: flex;
  gap: 0.6rem;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.1rem;
  & .menu-item,
  & a {
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
  }
  & .side-menu-icon-desktop {
    display: flex;
    & svg {
      width: 1rem;
    }
  }
}

.nav-header-light-text {
  & .logo-link {
    color: #fff;
    text-shadow: 0px 0px 2px #000000;
    font-size: 1.1rem;
  }
  & .top-menu {
    & .menu-item,
    & a {
      color: #fff;
      text-shadow: 0px 0px 5px #00000099;
    }
  }
}

.input-text {
  padding: 0.5rem;
  font-size: 1rem;
}

.button-small {
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #fff;
  display: inline-block;
  font-size: 1rem;
}

.actions-block {
  display: flex;
  gap: 0.5rem;
}

.brand-instagram {
  width: 2rem;
  & svg {
    fill: #fff;
    width: 100%;
  }
}

.contact-page-content {
  display: flex;
  column-gap: 3rem;
  row-gap: 0;
  margin: 1rem 0;
  flex-wrap: wrap;
  & .contact-page-image {
    width: 100vw;
    max-width: 20rem;
    & img {
      width: 100%;
      border-radius: 0.5rem;
    }
    & .photo-mobile {
      border-radius: 0;
      width: 100vw;
    }
  }
  & .contact-page-text {
    flex: 3;
  }
}

.photo-mobile {
  display: none;
}

.page-content {
  min-height: 60vh;
}

@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-thumbnail {
  display: inline-block; /* Ensure the button stays inline */
}

.image-gallery-thumbnail-inner {
  display: block; /* Make sure the inner span behaves as a block element */
  overflow: hidden; /* Hide overflow */
  height: 5rem; /* Set the fixed height */
}

.image-gallery-thumbnail-image {
  display: block; /* Make the image a block element */
  height: 100%; /* Make the image fill the container height */
  width: 100%; /* Make the image fill the container width */
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 3px solid #337ab7;
  border-radius: 0.8rem;
}

.image-gallery-thumbnail.hover,
.image-gallery-thumbnail:hover {
  outline: none;
  border: 3px solid #337ab7;
  border-radius: 0.8rem;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 60px;
  height: 60px;
}

.image-gallery-icon {
  filter: drop-shadow(0 1px 1px #1a1a1a);
}

.h3-header {
  font-size: 1.1rem;
  margin: 1.5rem 0 0rem;
}
.xhome-page {
  & .events-list {
    padding: 0;
  }
}

.guides-page {
  flex-direction: column;
  gap: 0;
  & .h3 {
    margin: 0rem 0;
    padding: 0;
    font-weight: 500;
  }
}

.guides-list {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 0;
}

@media only screen and (max-width: 850px),
  @media only screen and (max-width: 1400px) and (-webkit-device-pixel-ratio: 3),
  @media only screen and (max-width: 1000px) and (-webkit-device-pixel-ratio: 2) {
  .contact-page-content {
    margin: 0;
    & .contact-page-text {
      padding: 0 1.5rem;
    }
  }

  .photo-desktop {
    display: none;
  }
  .photo-mobile {
    display: inline-block;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border-radius: 0.5rem;
  }

  .image-gallery-thumbnail.hover,
  .image-gallery-thumbnail:hover {
    border-radius: 0.5rem;
  }

  .image-gallery-thumbnail-image {
    border-radius: 0.3rem;
  }

  .h3-header {
    margin: 0;
    padding: 1rem 1.5rem 1rem;
    border-top: 1px solid #f0f0f0;
  }

  .h3-header-second {
    margin: 1.5rem 0 0rem;
    padding: 1rem 1.5rem 2rem;
    border-top: 1px solid #eee;
  }

  & .events-list {
    padding: 0 1.5rem;
  }

  .events-list {
    & .event-card {
      & .event-info {
        padding: 0.6rem 0.1rem 1.6rem;
      }
    }
  }

  .profile-avatar {
    width: 100%;
  }

  .guides-page {
    padding: 0;
    & .h3 {
      padding: 0 1rem;
    }
  }

  .guides-list {
    padding: 0 1rem;
  }
}

.box-alert {
  font-weight: 500;
  color: #be1b1b;
}

.admin-user-data {
  max-width: 17rem;
  overflow: scroll;
  .icon-copy {
    margin-left: 0.5rem;
    width: 1.4rem;
    padding: 0.2rem 0.3rem;
    margin-bottom: -0.3rem;
    cursor: pointer;
    fill: #bbb;
  }
}

.pointer {
  cursor: pointer;
}

.account-lines {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.stripe-info {
  margin-bottom: 1rem;
  max-width: 40rem;
}

.balance-details {
  border-top: 1px solid #eee;
  margin: 0.5rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & .label {
    font-weight: 700;
  }
  & .number {
    font-weight: 600;
  }
  & .hr {
    margin: 1rem 0;
    border-top: 1px solid #eee;
  }
}

.tips {
  margin: 1rem 0;
  & ul {
    margin: 0.5rem 0;
  }
  & li {
    padding: 0.1rem 0;
  }
}

.input-legend {
  margin-top: 0.5rem;
  color: #666;
}

.field-label {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.item-list {
  white-space: pre-line;
}

.calendar-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  padding: 1rem;
  & button {
    max-width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
  }
}

.react-datepicker {
  font-family: inherit;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.icon-line {
  display: flex;
  gap: 0.6rem;
  & svg {
    width: 1rem;
  }
}

.a-with-box {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  cursor: pointer;
}

.icon-box {
  display: flex;
  gap: 0.6rem;
  padding: 0.3rem;
  background: #ff0000;
  border-radius: 0.3rem;
  max-width: 2rem;
  text-align: center;
  & svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: #fff;
  }
}

.event-rating {
  & svg {
    width: 0.9rem;
    height: 0.9rem;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.participants {
  display: flex;
  gap: 0.5rem;
  overflow: scroll;
  align-items: flex-start;
  flex-direction: column;
  max-height: 7rem;
}

.button-with-icon {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.3rem;
  width: 2rem;
  width: 2rem;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 0.5rem;
  }
}

.icon {
  width: 0.9rem;
  height: 0.9rem;
}

.guide-details {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
}
.icon-pair {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.help-box {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  border: 1px solid #a3c9ff;
  border-radius: 0.5rem;
  & svg {
    width: 1.2rem;
  }
}

.images-list {
  max-width: 100vw;
  & img {
    width: 100%;
    max-width: 20rem;
  }
}
