.event-header {
  display: flex;
  gap: 0.8rem;
  & .icon {
    width: 0.8rem;
    height: 0.8rem;
  }
  & .icon-medium {
    width: 1.1rem;
    height: 1.1rem;
    xtransform: rotate(-45deg);
  }
}

.events-list {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  gap: 1.3rem;
  flex: 1;
  height: 100%;
  align-items: stretch;
  & .event-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--background-color);
    min-width: 19rem;
    max-width: 20rem;
    text-decoration: none;
    xborder: 1px solid #f0f0f0;
    xborder-radius: 0.3rem;
    & .event-image {
      width: 100%;
      height: 18.5rem;
      object-fit: cover;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100vw;
        border-radius: 0.8rem;
      }
    }
    & .event-video {
      flex: 1;
      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.8rem;
      }
    }
    & .event-info {
      flex: 2;
      padding: 0.7rem 0.1rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      & h4 {
        margin: 0;
        padding: 0;
        font-weight: 600;
      }
      & h4.header-spacing {
        margin-bottom: 0.5rem;
      }
    }
    & .user-card {
      font-weight: 400;
      gap: 0.4rem;
      & img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    & .event-name {
      font-size: 1.1rem;
      font-weight: 500;
    }

    & .event-description {
      flex: 0;
      padding: 0;
      display: flex;
      gap: 0.3rem;
      flex-direction: column;
    }
    & .event-tickets {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
    }
    & .event-guides {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      & .a-guide {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        text-decoration: none;
        & img {
          width: 2rem;
          height: 2rem;
          border-radius: 100%;
          object-fit: cover;
        }
        & video {
          width: 1.12rem;
          height: 2rem;
          object-fit: cover;
          border-radius: 0.3rem;
        }
      }
      & .guides {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        & a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
          text-decoration: none;
        }
        & img {
          width: 2rem;
          height: 2rem;
          border-radius: 100%;
          xborder: 1px solid #3a3a3a;
        }
      }
    }
    & .event-book {
      padding: 0.5rem 0;
      display: flex;
      gap: 0.4rem;
      & button {
        background: #7212ac;
        color: #fff;
        font-weight: 800;
        border-radius: 1rem;
        padding: 1rem;
        width: 100%;
        border: none;
        cursor: pointer;
        outline: none;
        text-transform: uppercase;
      }
    }
  }
}

.date-value {
  text-transform: capitalize;
  font-weight: 400;
}

.event-info {
  padding: 0;
  display: flex;
  & .event-info-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & ul {
    & li {
      padding: 0;
      margin: 0;
      margin-top: 0.7rem;
    }
  }
  & .event-name {
    font-size: 1.4rem;
    font-weight: 600;
  }
  & .event-view {
    display: flex;
  }
  & .desktop-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 14rem;
    & .menu-item {
      padding: 1rem;
      font-weight: 500;
      text-decoration: none;
      xfont-size: 1.2rem;
      cursor: pointer;
      xtext-align: right;
      border-top: 1px solid #89898931;
    }
    & .menu-item-last {
      border-bottom: 1px solid #89898931;
    }
    & .search-input {
      padding: 1rem;
      font-size: 1rem;
      border-radius: 0.5rem;
      border: 1px solid #89898931;
      background: transparent;
      xcolor: #fff;
      width: 100%;
    }
    & .logo-item {
      text-align: left;
      font-weight: 800;
      font-size: 1.2rem;
      letter-spacing: 0.07rem;
      text-transform: uppercase;
    }
    & .footer {
      padding: 1rem;
      background: none;
      margin: 0;
      & .copyrights {
        font-size: 0.9rem;
        color: #8787878e;
        justify-content: flex-start;
      }
      & .select-boxes {
        flex-direction: column;
        & .select-box {
          justify-content: flex-start;
          align-items: stretch;
          flex-direction: column;
          gap: 0.5rem;
          & select {
            flex: 1;
          }
        }
      }
      & .social {
        justify-content: flex-start;
      }
    }
  }
  & .event-videos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    align-items: flex-start;
    flex-wrap: wrap;
    & video,
    & img {
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  & .event-type {
    color: #666;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
  & .event-description {
    flex: 2;
    padding: 0.5rem 2rem;
  }
  & .event-details-line {
    margin: 0.5rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & .event-desc {
    white-space: pre-line;
    font-weight: 400;
    line-height: 1.5rem;
    & ul {
      padding: 0 1rem;
      margin: 0;
    }
  }
  & .event-header {
    font-weight: 500;
  }
  & .event-price,
  & .event-duration {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    xfont-weight: 400;
    & .event-price-value {
      xfont-weight: 500;
    }
  }
  & .event-rating-value {
    xfont-weight: 400;
  }
  & .event-price-name {
    xfont-weight: 600;
  }
  & .event-price-age {
    color: #777;
  }
  & .event-rating {
    & .event-rating-label {
      font-weight: normal;
      color: #666;
    }
  }
  & .user-card {
    xfont-weight: 400;
  }
  & .event-guide {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    & img {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      object-fit: cover;
    }
    & video {
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 0.3rem;
      object-fit: cover;
    }
  }
  & .description {
    margin: 2rem 0;
    font-weight: 400;
  }
  & .header-semi {
    margin: 0 0 1.5rem;
    padding: 0;
    font-weight: 600;
    font-size: 1.3rem;
    border-top: 1px solid #eee;
    padding-top: 1rem;
  }
  & .supplier-description {
    margin-top: 1rem;
    font-weight: 400;
    white-space: pre-line;
  }
  & .about {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    & .user-card {
      gap: 1rem;
      & img {
        width: 4rem;
        height: 4rem;
      }
    }

    & .activities {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 1.5rem;
      & .item {
        display: flex;
        gap: 1rem;
        & .icon {
          font-size: 1.4rem;
          width: 1.2rem;
          min-width: 1.2rem;
          text-align: center;
          & svg {
            width: 100%;
          }
        }
        & .item-header {
          font-weight: 600;
        }
        & .item-content {
          font-weight: 400;
          font-size: 1rem;
        }
        & .item-details {
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
          & .item-details-header {
            font-weight: 600;
            font-size: 1.1rem;
          }
        }
        & .item-subdesc {
          color: #444;
          font-weight: 400;
        }
      }
    }
  }

  & .item-subdesc-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & .event-slot-button {
    cursor: pointer;
  }
  & .event-slot-button-primary {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
  }
}

.users-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.5rem;
  & .user-card {
    width: 100%;
    max-width: 100%;
    font-size: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ccc;
  }
  & .user-actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    & * {
      cursor: pointer;
    }
  }
}

.footer {
  background: #222;
  margin-top: 5rem;
  padding: 3rem 3rem 3rem;
  color: #fff;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  & .footer-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: var(--max-width);
    gap: 1rem;
  }
  & a {
    color: #fff;
    text-decoration: none;
    border: 0;
  }
  & .footer-sections {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
  }
  & .social {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    flex: 1;
    justify-content: flex-end;
  }
  & .social-icon-yt {
    font-size: 1.8rem;
  }
  & .social-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  & .footer-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    xflex: 1;
    & span {
      width: 5rem;
      display: inline-block;
      text-align: center;
    }
    & select {
      padding: 0.5rem;
      border-radius: 0.5rem;
      background: transparent;
      color: #fff;
    }
  }
  & .footer-contact-details {
    font-weight: 600;
    gap: 1rem;
    align-items: flex-start;
  }
  & .footer-section-full {
    width: 100%;
    display: flex;
    flex-direction: row;
    & .copyrights {
      flex: 1;
      display: flex;
      align-items: center;
      color: #ffffffae;
    }
  }
  & .links {
    display: flex;
    gap: 1rem;
  }
  & .select-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .footer-section-links {
    align-items: center;
  }
  & .select-boxes {
    flex: 1;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
  & .fa-instagram {
    font-size: 2rem;
  }
  & .fa-tiktok {
    font-size: 1.55rem;
    padding: 0.1rem 0.3rem;
  }
}

.event-description .event-actions {
  display: flex;
  gap: 0.5rem;
  margin: 2rem 0 0;
  flex-wrap: wrap;
  & .button-secondary {
    width: auto;
  }
}

.react-datepicker-wrapper {
  & input::placeholder {
    color: #000;
  }
}

.home-event-list {
  padding: 1rem;
}

.event-list-container {
  width: 100%;
}

.my-events {
  & .event-list-item {
    border-bottom: 1px solid #eee;
  }
  & .event-actions {
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
    flex-wrap: wrap;
    & .button-secondary {
      width: fit-content;
    }
  }
}

.event-slots-tickets {
  & .event-slots-tickets-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.5rem 0 2rem;
    padding: 0rem;
  }
  & .event-name {
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 1px solid #e9e9e9;
    width: auto;
  }
  & .event-date {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  & .event-tickets-number {
    font-size: 1.1rem;
    font-weight: 600;
  }
  & .orders {
    max-width: 100vw;
    overflow: scroll;
    width: 100%;
    & table {
      & th {
        padding: 0.5rem;
        border-bottom: 1px solid #e9e9e9;
        text-align: left;
      }
      & td {
        padding: 0.5rem;
        border-bottom: 1px solid #e9e9e9;
      }
      & .order-pending td {
        background: #fff3b7;
      }
      & .order-canceled td {
        background: #e9e9e9;
      }
      & .order-refunded td {
        background: #ffb5b5;
      }
    }
  }
}

a.a-text {
  text-decoration: none;
  color: #000;
  border: 0;
}

.edit-event-slots {
  & table {
    & th {
      padding: 0.5rem;
      border-bottom: 1px solid #e9e9e9;
      text-align: left;
    }
    & td {
      padding: 0.5rem;
      border-bottom: 1px solid #e9e9e9;
    }
    & td.event-slot-data-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      & a {
        text-decoration: none;
        color: #000;
        border: 0;
      }
    }
    & .order-canceled td {
      background: #e9e9e9;
    }

    & .order-refunded td {
      background: #e4e3e3;
    }
  }
  & .button-action {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    display: inline-block;
  }
}

.form-event-slot {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  max-width: 30rem;
  & .react-datepicker__input-container {
    & input {
      padding: 1rem;
      width: 14rem;
      border-radius: 0.5rem;
      border: 1px solid #ccc;
      cursor: pointer;
      font-size: 1rem;
    }
  }
  & .input-tickets {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
  }
}

.ticket-actions {
  display: flex;
  gap: 1rem;
}
.button-default {
  border: 1px solid #ccc;
  background: #fafafa;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.form-profile {
  min-width: 50rem;
  & .description {
    min-height: 20rem;
  }
}
.form-actions {
  margin-top: 1rem;
}
.form-info-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid #eee;
  padding: 1rem;
}

ul {
  & li {
    padding: 0.5rem;
  }
}

.profile-avatar {
  & .default-avatar {
    width: 360px;
    padding: 1rem;
  }
}

.balance {
  padding: 1rem;
  xbackground: #eaeaea;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  font-weight: 700;
  & .label {
    font-size: 0.8rem;
  }
  & .number {
    font-size: 1.1rem;
  }
}

.profile-media-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  & div {
    flex: 1;
  }
}

.status-pending {
  background: #f0f0f0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0 0 0.5rem;
}

.media-actions {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  align-items: center;
}

.event-image-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  & .event-image {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & img {
      width: 100%;
      height: 10rem;
      border-radius: 0.5rem;
    }
  }
}

.event-video-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  & .event-video {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & video {
      width: 100%;
      height: 20rem;
      border-radius: 0.5rem;
    }
  }
}

.users-list-table {
  & th {
    text-align: left;
  }
  & th,
  td {
    padding: 0.5rem;
  }
  & .td-action {
    cursor: pointer;
  }
  & .tickets-count {
    text-align: center;
  }
}

.admin-content {
  width: 100%;
  overflow: scroll;
  & .h3 {
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.profile {
  padding: 0rem;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  width: 100%;
  & .profile-avatar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 25rem;
  }
  & .profile-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & .profile-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .event-list {
      flex-direction: column;
    }
  }
  & .profile-desc {
    white-space: break-spaces;
  }
  & .avatar {
    max-width: 100%;
    border-radius: 0.5rem;
  }
  & video {
    height: calc(100vh - 5rem);
    border-radius: 0.3rem;
  }
  & .profile-header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    & .avatar {
      width: 10rem;
      border-radius: 100%;
    }
    & h1 {
      margin: 0.1rem 0;
      padding: 0;
      font-size: 1.2rem;
      & .user-card span {
        font-weight: 600;
      }
    }
    & h3 {
      margin: 0.5rem 0;
      padding: 0;
      font-size: 1.5rem;
    }
    & h5 {
      margin: 0;
      padding: 0 0 0.2rem;
      color: #444;
      font-weight: 500;
    }
  }
  & .social {
    display: flex;
    gap: 0.8rem;
    padding: 1rem 0 0;
    font-size: 1.5rem;
    & svg {
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      height: 2rem;
      width: 2rem;
      justify-content: center;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    & .fa-youtube {
      font-size: 1.8rem;
    }
  }
  & .profile-actions {
    display: flex;
    gap: 1rem;
    padding: 1rem 0 0;
    & .upload-file-panel {
      flex: 1;
    }
    & .edit-profile-button {
      width: auto;
    }
  }
}

.profile {
  & .h3 {
    padding: 0.3rem 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  & .button-primary {
    width: auto;
    font-size: 1rem;
  }
}

.promo-list {
  margin: 0;
  padding: 1rem;
  & li {
    padding: 0 0 0.5rem;
    margin: 0;
  }
}

.info-lines {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-line-checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  & label {
    cursor: pointer;
  }
  & input {
    height: 1.5rem;
    flex: 0;
  }
}

.event-image-processing {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;
}

.box-info {
  padding: 1rem;
  background: #fff3b7;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.form-review {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid #ccc;
  margin-top: 0.5rem;
  padding-top: 1.5rem;
  & .review-stars {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #888;
  }
  & .rating-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & .rating-label {
      font-size: 1rem;
      font-weight: 600;
    }
    & .rating-description {
      font-size: 0.9rem;
    }
  }
  & i {
    cursor: pointer;
    font-size: 2rem;
  }
  & textarea {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    width: 100%;
    min-height: 12rem;
    font-size: 1rem;
  }
  & .tip {
    display: flex;
    gap: 1rem;
    justify-items: center;
    align-items: center;
  }
  & input {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  & .button-primary {
    width: auto;
  }
  & .review-actions {
    display: flex;
    gap: 1rem;
  }
}

.event-reviews {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0 0rem;
  & .h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }
  & .event-review-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  & .event-subheader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & .event-review-date {
    font-size: 0.9rem;
    color: #666;
  }
  & .event-review {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    & .event-review-content {
      white-space: pre-line;
      font-weight: 400;
    }
    & .review-user {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      & img {
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
      }
    }
  }
}

.form-section {
  margin: 1rem 0 0;
}
.form-input-flex {
  display: flex;
  gap: 1rem;
  align-items: center;
  & .label {
    white-space: nowrap;
  }
}

.box-tip {
  padding: 1rem;
  background: #fafafa;
  border-radius: 0.5rem;
  margin: 0 0 0.5rem;
  & .box-tip-close {
    float: right;
    cursor: pointer;
  }
}

.textarea-default {
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  height: 10rem;
}

.tabs-actions {
  display: flex;
  gap: 1rem;
  & .button {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    display: inline-block;
  }
  & .active {
    border: 1px solid #000;
  }
}

.drag-item-container {
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
}
.drag-item-container-inner {
  position: relative;
}
.drag-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: move;
}

.is-dragging {
}

@media only screen and (max-width: 850px),
  @media only screen and (max-width: 1400px) and (-webkit-device-pixel-ratio: 3),
  @media only screen and (max-width: 1000px) and (-webkit-device-pixel-ratio: 2) {
  .event-header {
    flex-wrap: wrap;
  }
  .event-slots-tickets {
    & .event-slots-tickets-info {
      margin: 0.5rem 0 1rem;
      padding: 1rem;
    }
  }

  .form-profile {
    min-width: auto;
  }

  .profile-avatar {
    max-width: 100%;
    & .default-avatar {
      width: 100%;
      max-width: 360px;
    }
  }

  .admin-content {
    & .h3 {
      padding: 1rem;
    }
  }

  .event-reviews {
    margin: 1.5rem 1.5rem;
  }
  .about {
    margin: 1rem 0;
  }
}
