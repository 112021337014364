:root {
  --color: #000;
  --background-color: #fff;
  --max-width: 1340px;
  --font-family: 'Open Sans', Arial, Tahoma, Verdana, sans-serif;
}

.dark,
.dark #root {
  --color: #fff;
  --background-color: #161616;
}

body {
  font-family: var(--font-family);
  margin: 0 auto;
  color: var(--color);
  background: var(--background-color);
  font-weight: 500;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea,
input,
select {
  font-family: var(--font-family);
  color: var(--color);
  font-size: 1rem;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--color);
  text-decoration: none;
  xborder-bottom: 1px solid var(--color);
}

.link-basic {
  text-decoration: none;
  border-bottom: 1px solid var(--color);
}

#root {
  display: flex;
  flex-direction: column;
  xmin-height: 100vh;
  background-color: var(--background-color);
}

.app {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  & .content {
    margin: 0 auto;
    padding: 0;
    flex: 1;
    width: 100%;
    overflow: scroll;
    max-width: var(--max-width);
  }
}

.app-desktop {
  min-height: 100vh;
}

.main-container {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

.categories {
  padding: 0;
  display: flex;
  gap: 0.5rem;
  font-size: 1.3rem;
  gap: 1rem;
  & .category-button {
    font-weight: 600;
    color: #000;
    padding: 0.5rem 0;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.categories-cities {
  font-size: 1.6rem;
  & .category-button {
  }
}

.container-basic {
  display: flex;
}

.menu {
  width: 52vw;
  width: 100vw;
  max-width: 15rem;
  height: 100vh;
  z-index: 9999999990;
  right: 0;
  top: 0;
  overflow: scroll;
  position: fixed;
  xbackground: linear-gradient(190deg, #0a0a0a, #160000);
  background: #fff;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #251a1a;
  animation: none;
  -webkit-transform: translateX(1000px);
  transform: translateX(1000px);
  & a {
    xfont-weight: 700;
    padding: 1rem;
    text-decoration: none;
    border-bottom: 1px solid #8686861f;
  }
}

.menu-bg {
  width: 100vw;
  height: 100vh;
  z-index: 999999990;
  top: 0;
  position: fixed;
  background: #00000082;
  display: none;
}

.menu-bg-active {
  display: inline-block;
}

.side-menu-icon {
  position: fixed;
  z-index: 999999990;
  right: 0;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
  font-size: 1.5rem;
  padding: 0.7rem 1rem;
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
  }
}

.side-menu-text-icon {
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-weight: 800;
}

.side-menu-close-icon {
  position: fixed;
  z-index: 999999990;
  right: 2.2rem;
  top: 0;
  width: 3.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.7rem 1rem;
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  & svg {
    width: 100%;
  }
}

.side-menu-icon-light {
  color: #fff;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.content-filler {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  & .info-box-small {
    padding: 3rem;
    line-height: 1.3rem;
    max-width: 100%;
    overflow: hidden;
    & a {
      color: #045b97;
      border-bottom: 1px solid #045b97;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6rem;
  font-size: 2rem;
  & svg {
    animation-duration: 1s;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#feed {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: absolute;
  padding-bottom: 5rem;
}

.details {
  width: 100vw;
  font-weight: 600;
  background: #111;
  display: flex;
  flex-direction: column;

  & .details-panel {
    flex: 1;
    display: flex;
    align-items: flex-end;
    width: 100vw;
    xheight: 100vh;
    height: 100%;
    z-index: 9999;
    position: absolute;
    flex-direction: column;
    justify-content: flex-end;
  }
  & .details-panel-short {
    padding-bottom: 5rem;
  }
  & .event-card {
    flex: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0rem;
    width: 100%;
    color: #fff;
    text-shadow: 1px 1px 3px #0000007d;

    & .event-image {
      width: 100%;
      height: 10rem;
      object-fit: cover;
      border-radius: 0.5rem;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }
    & .event-info {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      user-select: none;
      width: 100vw;
      text-shadow: 0px 0px 5px #000;
    }
    & .event-name {
      font-size: 1.3rem;
      font-weight: 600;
    }
    & .event-description {
    }
    & .event-stops {
    }
    & .event-price {
      font-weight: 700;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      justify-content: flex-start;
    }
    & .stars {
      color: #fff;

      & .event-rating-stars {
        & .xstars-empty {
          grid-row: 1;
          grid-column: 1;
          z-index: 1;

          display: flex;
          gap: 0.4rem;
          color: #ffffff61;
          top: 0;
          left: 0;
          z-index: 0;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
        }
      }
    }
    & .event-guides {
      display: flex;
      gap: 0.3rem;
      xalign-items: center;
      flex-direction: column;
      & .guides {
        display: flex;
        gap: 0.3rem;
        & a {
          color: #fff;
          font-weight: 600;
        }
        & img {
          width: 2.6rem;
          height: 2.6rem;
          xborder-radius: 0.5rem;
          xborder: 2px solid #ffffff7a;
          xbox-shadow: 2px 2px 2px #0000002e;
          xborder: 1px solid #ffffffba;
          border: 1px solid #ffffff;
        }
      }
      & .link-more-items {
        width: 2.6rem;
        height: 2.6rem;
        background: #00000049;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border-radius: 0.5rem;
      }
    }
    & .event-book {
      padding: 0.5rem 0;
      display: flex;
      gap: 0.4rem;

      & button {
        background: #7212ac;
        color: #fff;
        font-weight: 800;
        border-radius: 1rem;
        padding: 1rem;
        width: 100%;
        border: none;
        cursor: pointer;
        outline: none;
        text-transform: uppercase;
      }
    }
    & .action-icons {
      display: flex;
      flex-direction: row;
      gap: 0.7rem;
      margin: 0.5rem;
      & svg {
        flex: 1;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.5rem;
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.panel-actions {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 0.5rem 0 0;
  gap: 1rem;
  & i {
    font-size: 2rem;
    text-shadow: none;
  }
  & .button-action {
    color: #000;
    background: #ffffffe3;
    flex: 1;
    xbox-shadow: 2px 2px 2px #0000002e;
    text-shadow: none;
    text-align: center;
    font-weight: 600;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0rem;
    cursor: pointer;
    text-transform: uppercase;
    min-width: fit-content;
    white-space: nowrap;
  }
  & .button-action-primary {
    color: #000;
    background: #ffbf39;
    border: 1px solid #eba203;
  }
  & .button-action-secondary {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
  }
}

.main-bottom-actions {
  display: flex;
  flex-direction: column;
  margin: 0;
  background: #fff;
  xbackground: #ffffffb5;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999999;
  & .buy-tickets-button {
    cursor: pointer;
    width: 100%;
    background: #fff;
    padding: 1rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-self: center;
    margin: 0;
    background: #fff;
    & i {
      font-size: 1.3rem;
    }
    & .button-action {
      color: #000;
      flex: 1;
      text-shadow: none;
      text-align: center;
      font-weight: 700;
      border-radius: 1rem;
      padding: 1rem 0.5rem;
      margin: 0rem;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.8rem;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
    }
  }
}

.video-canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-canvas {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100vw;
  position: relative;
}
.card-current {
  z-index: 99999999;
}

.form {
  & input,
  & select,
  & option,
  & textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
  }
  & .textarea-large {
    height: 20rem;
  }
}

@keyframes moveOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    xtransform: translateX(-100vw);
  }
}

.move-out-left {
  animation: moveOutLeft 300ms linear;
}

@keyframes moveInLeft {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

.move-in-left {
  animation: moveInLeft 300ms linear;
}

@keyframes moveOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}

.move-out {
  animation: moveOut 300ms linear;
}

@keyframes moveIn {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.move-in {
  animation: moveIn 300ms linear;
}

.card-prev {
  animation: moveOut 200ms linear;
  xtransform: translateY(-100vh);
}
.card-next {
  transform: translateY(100vh);
}
.card-current {
  xtransform: translateY(0);
  animation: moveIn 200ms linear;
}
.card-first {
  animation: none;
}

.start-canvas {
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999999999;
  background: #000000a6;
  background: #000000c9;
  color: #fff;
  xheight: 100vh;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 800;
  & button {
    xbackground: transparent;
    xcolor: #fff;
    border: 1px solid #fff;
    padding: 1rem;
    width: 5rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    font-weight: 800;
    background: #fff;
    color: #000;
  }
}

.header-h2 {
  padding: 1rem 0;
  font-weight: 600;
}
.header-h2-space {
  padding: 1rem 0 1rem;
  font-weight: 600;
}

.unmute-button {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 1.2rem;
  border-radius: 1rem;
  font-weight: 800;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 99999999;
  & i {
    margin-right: 0.5rem;
  }
}

.home-page {
  flex: 1;
  justify-content: center;
  display: flex;
  gap: 0;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.home-page-content {
  max-width: var(--max-width);
}

.content-page {
  padding: 10vh 1rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.form {
  margin: 1rem auto;
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & textarea {
    padding: 1rem;
    font-size: 1rem;
    width: 100%;
    min-height: 12rem;
  }
}

.form-inline {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  & input {
    padding: 0.5rem;
    font-size: 1rem;
  }
  & .button-primary {
    padding: 0.8rem;
  }
}

.form-event {
  margin: 1rem 0;
  & .react-datepicker-wrapper {
    & input {
      width: 8rem;
    }
  }
  & .input-text {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
  }
  & .input-age {
    width: 5rem;
  }
  & .input-number-small {
    width: 8rem;
  }
  & .textarea {
    font-size: 1rem;
    width: 100%;
    padding: 0.5rem;
    height: 30rem;
    min-height: auto;
  }
  & .textarea-short {
    height: 2.5rem;
  }
  & .form-tickets {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
  }
  & .form-line-ticket {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    & .ticket-name {
      font-weight: 700;
    }
  }
  & .button-inline {
    margin: 0;
    width: fit-content;
  }
  & .subitems-actions {
    display: flex;
    gap: 1rem;
    flex-direction: row-reverse;
  }
}

.form-basic {
  margin: 1.5rem auto 4rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  width: 100%;
  & .form-header {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  & .h3 {
    padding: 0;
    margin: 0;
  }
  & .line {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & input {
    font-size: 1rem;
    padding: 0.8rem;
    max-width: 25rem;
    border-radius: 0.3rem;
    border: 1px solid #999;
  }
  & .button-submit {
    font-size: 1rem;
    padding: 1rem;
    border: 0;
    border-radius: 1rem;
  }
  & .input-checkbox {
    margin-right: 0.5rem;
  }
  & .info {
    display: block;
    line-height: 1.3rem;
  }
}

.sign-up-supplier-content {
  display: flex;
  gap: 3rem;
  margin: 1rem 0;
  & .photo {
    border-radius: 1rem;
    width: 40rem;
    max-width: 30vw;
  }
  & .photo-mobile {
    display: none;
  }
}

.form-sign-up-supplier {
  padding: 0;
  margin: 1rem;
  width: 100%;
  max-width: max-content;
  gap: 1rem;
  & .button-submit {
    margin: 1rem 0;
  }
}

.thanks {
  margin: 1rem 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  background: #049710;
  padding: 1rem;
  color: #fff;
}

.tip-1 {
  background: #0000008f;
  color: #fff;
  padding: 1rem;
  font-weight: 600;
  /* margin: 1rem; */
  border-radius: 1rem;
  text-align: center;
  text-transform: uppercase;
  & i {
    margin: 0 1rem;
  }
  /* z-index: 9;
  margin-top: 5rem; */
}

.button-submit {
  xbackground: #007734;
  background: #3469bf;
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  border: none;
  cursor: pointer;
  outline: none;
}

.intro {
  margin: auto;
  display: flex;
  & div {
    padding: 2rem;
    font-weight: 500;
    font-size: 1.4rem;
  }
  & img {
    height: 100vh;
  }
}

.Toastify {
  position: absolute;
  z-index: 99999999999;
}
.Toastify__toast-theme--light {
  background: var(--background-color);
  color: var(--color);
}
.Toastify__toast-icon {
  margin-inline-end: 1rem;
}
.Toastify__toast-container--bottom-center {
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 1rem;
  font-weight: 500;
}

.hide {
  display: none;
}

.video-canvas-filter {
  filter: brightness(2);
}

.subheader {
  padding: 1rem 0rem 0;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}

.event-rating {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-weight: 500;
  & .event-rating-label {
    font-weight: 600;
  }
}

.stars {
  gap: 0.1rem;
  align-items: center;
  text-wrap: nowrap;
  font-size: 1rem;
  display: flex;
  flex-wrap: nowrap;
  & svg {
    width: 2rem;
  }
  & .fa-regular {
    fill: #0483e0;
  }
  & .fa-solid {
    fill: #035196;
  }
}

.event-review-rating {
  & .stars {
    & svg {
      width: 1rem;
    }
    & .fa-regular {
      fill: var(--color);
    }
    & .fa-solid {
      fill: var(--color);
    }
  }
}
.actions {
  display: flex;
  gap: 1rem;
}

.event-list-header {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 2rem 0 1.5rem;
}

.zzzevent-description {
  .event-list {
    flex-direction: column;
  }
}
.event-list {
  display: flex;
  gap: 1rem;
  max-width: 100%;
  flex-wrap: wrap;
  & .event-list-item {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    cursor: pointer;

    & .event-image {
      max-height: 20rem;
      border-radius: 0.5rem;
      width: 100%;
      object-fit: cover;
    }
    & .event-data {
      display: flex;
      gap: 1rem;
      padding: 0 0.5rem;
      flex-direction: column;
      & .event-name {
        font-size: 1.2rem;
        font-weight: 600;
      }
      & .event-price {
        display: flex;
        gap: 0.3rem;
        align-items: center;
      }
      & .event-guide {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        & img {
          width: 2rem;
          height: 2rem;
          border-radius: 100%;
        }
      }
      & .event-data-header {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
      }
      & .event-data-details {
        display: flex;
        gap: 1.5rem;
      }
    }
  }
}

.form-book {
  margin: 3rem 0 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 1rem;
  & .form-sign-up {
    gap: 1.5rem;
    margin: 1rem 0;
    border-top: 1px solid #eee;
    padding: 1.5rem 0 0;
    width: 100%;
    max-width: 100%;
  }
  & .form-header {
    font-weight: 600;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }
  & .form-sub-header {
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #ddd;
  }
  & .h3 {
    padding: 0;
    margin: 0;
  }
  & .line {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & .info {
    display: block;
  }
  & input {
    font-size: 1rem;
    padding: 0.8rem;
    font-size: 1rem;
    max-width: 22rem;
    border-radius: 0.3rem;
    border: 1px solid #333;
  }
  & .button-submit {
    max-width: 16rem;
    border: 0;
    border-radius: 0.5rem;
  }
  & .form-items {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    & .form-item {
      display: flex;
      gap: 1rem;
      padding: 1rem 0;
      border-bottom: 1px solid #eee;
      & .form-price-value {
        align-items: center;
        justify-content: center;
        display: flex;
      }
      & .form-item-info {
        width: 5rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
      }
      & .form-item-price {
        width: 5rem;
        text-align: center;
      }
      & .form-item-label {
        font-weight: 600;
      }
      & .form-inputs {
        display: flex;
      }
      & .form-number-selector {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        & input {
          width: 4rem;
        }
        & .icon-button {
          cursor: pointer;
          padding: 0.5rem;
          width: 2rem;
        }
        & .icon-button-disabled {
          color: #eee;
          fill: #eee;
        }
      }
      & .form-date-selector input {
        width: 8rem;
      }
      & select,
      & option {
        width: 8rem;
        padding: 0.5rem;
        font-size: 1rem;
      }
    }
    & .form-item:first-of-type {
      border-top: 1px solid #eee;
    }
    & .form-item-last {
      border-bottom: 0;
    }
    & .form-input-selector {
      padding: 0.6rem;
      border: 1px solid #222;
      display: flex;
      gap: 1rem;
      border-radius: 0.2rem;
    }
    & .form-summary {
      font-weight: 600;
    }
    & .form-filler {
      flex: 1;
    }
  }
  & .form-book-button {
    max-width: 15rem;
    padding: 1.2rem 2rem;
    text-transform: none;
    font-size: 1rem;
  }
}

.event-selector {
  display: flex;
  flex-direction: column;
  border: 1px solid #666;
  border-radius: 0.7rem;
  cursor: pointer;
  width: fit-content;

  & .event-selector-item {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    gap: 1rem;
  }
  & .event-selector-item-place {
    font-weight: 600;
  }
  & .event-selector-item-datetime {
    text-transform: capitalize;
  }
  & .event-selector-left {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    flex: 1;
  }
  & .event-selector-icon {
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 1.2rem;
    display: flex;
    & svg {
      width: 100%;
    }
  }
  & .event-selector-item:first-of-type {
    border-top: 0;
  }
  & .event-button {
    border: 1px solid #878787;
    border-radius: 0.5rem;
    padding: 1.5rem 2rem;
    cursor: pointer;
  }
  & .event-selector-item-label {
    & .user-header {
      margin: 1rem 0 0.5rem;
    }
  }
}
.event-selector-simple {
  border: 0;
  & .event-selector-item {
    padding: 0;
  }
}

.dialog-event-selector {
  gap: 0.5rem;
  border: 0;
}

.box-warning {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 500;
}

.box-info-semi {
  background: transparent;
  border: 1px solid #ebebeb;
}

.form-box-warning {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #b30505;
  border: 1px solid #d96b6b;
  border-radius: 0.5rem;
}

.form-box-info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #0467a5;
  border: 1px solid #5770c5;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  & a {
    color: #0467a5;
    border-bottom: 1px solid #5770c5;
  }
}

.dialog {
  background: #000000ad;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  & .dialog-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);
    max-height: 95vh;
    max-width: 95vw;
    border-radius: 0.5rem;
  }
  & .dialog-drawer {
    max-width: 34rem;
    right: 0;
    top: 0;
    height: 100vh;
    max-height: 100vh;
  }
  & .dialog-header {
    padding: 1rem;
    flex: 0;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    border-bottom: var(--border-semi);
    width: 100%;
    display: flex;
    & .dialog-title {
      flex: 1;
      float: left;
    }
    & .x {
      cursor: pointer;
      font-weight: 800;
      xcolor: #aaa;
      flex: 0;
      float: right;
    }
  }
  & .dialog-content {
    background: var(--bg-color);
    padding: 1rem;
    flex: 1;
    overflow: scroll;
    width: 100%;
    xmax-height: 75vh;
    xheight: 100vh;
  }
  & .dialog-footer {
    border-top: var(--border-semi);
    width: 100%;
    padding: 1rem;
    flex: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 0;
    & .button {
      border: 0;
      font-weight: 600;
    }
  }
  & .dialog-info {
    padding: 0 0 1rem;
  }
  & .characters-list {
    margin: 0;
    padding: 0;
  }
}

.dialog-full {
  height: 95vh;
  height: 100%;
}

.form-price-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #eee;
  padding: 2rem;
  & .form-price-details-header {
    font-weight: 500;
  }
  & .form-price-detail {
    display: flex;
    gap: 0.5rem;
    flex: 1;
    max-width: 15rem;
    & .form-price-detail-label {
      flex: 1;
    }
    & .form-price-detail-value {
      font-weight: 400;
      display: flex;
      gap: 0.5rem;
      flex: 2;
      & * {
        flex: 1;
      }
    }
  }
  & .form-total-line {
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
    padding-top: 0.5rem;
    & .form-total-line-label {
      font-weight: 500;
    }
    & .form-total-line-value {
      font-weight: 800;
      font-size: 1.5rem;
    }
  }
}

.form-actions {
  display: flex;
  gap: 1rem;
}
.react-datepicker__header {
  background: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  padding: 0.5rem;
  width: 2.7rem;
  font-weight: 600;
}

.react-datepicker__navigation {
  height: 2.5rem;
  width: 2.5rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-primary {
  background: #035196;
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;
  padding: 1rem 2rem;
  width: 100%;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
}

.button-secondary {
  background: #fff;
  color: #035196;
  border: 1px solid #035196;
  font-weight: 600;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  outline: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-secondary-small {
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: auto;
  max-width: fit-content;
  text-transform: none;
}

.button-disabled {
  background: transparent;
  color: #aaa;
  border: 1px solid #eee;
}

.button-inline {
  display: inline-block;
  width: auto;
}

.page-content {
  width: var(--max-width);
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  flex: 1;
  & .h3 {
    padding: 0.3rem 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
}

.tickets-page {
  width: var(--max-width);
  margin: 0 auto;
  padding: 0;
  flex: 1;
}

.tickets-list {
  padding: 1rem 0 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.avatar-box {
  width: 100%;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  object-fit: cover;
}

.link-regular {
  color: var(--color);
  border-bottom: 1px solid var(--color);
  width: fit-content;
}

.ticket {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  border: 1px solid #ccc;
  padding-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  max-width: 30rem;
  & .link-basic {
    color: var(--color);
    border-bottom: 1px solid var(--color);
    width: fit-content;
  }
  & .event-guide {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    border: 1px solid #eee;
    & img {
      width: 3rem;
      border-radius: 100%;
    }
  }
  & .line {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  & .ticket-header {
    font-size: 1.3rem;
  }
  & .qr {
    width: 100%;
    max-width: 4rem;
    height: auto;
  }
  & .qr-line {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  & .ticket-datetime {
    text-transform: capitalize;
  }
  & .a-button {
    padding: 0.5rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    & i {
      margin-left: 0.5rem;
      float: right;
    }
  }
  & .supplier-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & .event-guide {
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    & .user-card-medium {
      & img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.ticket-refunded {
  border: 1px solid #be1b1b;
  background: #eee;
}

.contact-page {
  flex: 1;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  min-height: 70vh;
  padding: 0;
}

@media only screen and (max-width: 850px),
  @media only screen and (max-width: 1400px) and (-webkit-device-pixel-ratio: 3),
  @media only screen and (max-width: 1000px) and (-webkit-device-pixel-ratio: 2) {
  .app .page-content {
    padding: 0 1rem;
  }

  .home-page {
    padding: 0;
    gap: 0;
    & .home-subheader {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0rem 1rem;
      padding: 0;
    }
    & .page-filters-panel {
      margin: 0 1rem 1rem;
      flex-direction: column;
      & .input-datepicker-container {
        width: 100%;
        justify-content: start;
        & .custom-input {
          width: auto;
          display: flex;
          gap: 0.8rem;
        }
        & svg {
          width: 1rem;
          height: 1rem;
          fill: #aaa;
        }
      }
      & .search-container {
        width: 100%;
        justify-content: start;
        & .search-input {
          flex: 1;
        }
      }
    }
    & .event-list {
      & .event-data {
        padding: 0 1rem;
      }
      & .event-image {
        border-radius: 0;
      }
    }
  }

  .event-list-container {
    & .event-list-header {
      margin: 1rem 1.5rem;
    }
    & .event-list {
      & .event-data {
        padding: 0 1rem;
        & .event-data-details {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
      & .event-image {
        border-radius: 0;
      }
    }
  }

  .form-centered {
    margin: 1.5rem;
    width: auto;
    min-height: 50vh;
  }

  .sign-up-supplier-content {
    flex-direction: column;
    gap: 0;
    margin: 0;
    & .photo {
      display: none;
    }
    & .photo-mobile {
      display: inline-block;
      width: 100vw;
      max-width: 100vw;
      border-radius: 0;
    }
  }
  .form-sign-up-supplier {
    padding: 1rem;
    margin: 0;
    gap: 1rem;
  }

  .menu {
    max-width: 60vw;
  }

  .nav-header {
    padding: 1rem;
    top: 0;
    z-index: 999999;
    & .logo-link,
    & .top-menu .menu-item {
      xcolor: #fff;
      xfilter: drop-shadow(1px 1px 3px #00000079);
    }
  }

  .subheader {
    padding: 1rem;
  }

  .events-list {
    flex-wrap: wrap;
    flex-direction: column;
    & .event-card {
      xwidth: 100vw;
      width: 100%;
      max-width: 100vw;
      min-width: auto;
    }
  }

  .event-info {
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
    & .event-head {
      display: flex;
      align-items: center;
      & i {
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
    }
    & .event-details-line {
      margin: 1rem 0;
    }
    & .event-name {
      padding-right: 2rem;
      font-size: 1.5rem;
    }
    & .event-description {
      padding: 0;
    }
    & .event-description-content {
      padding: 0 1.5rem;
    }
    & .event-videos {
      display: flex;
      flex-direction: row;
      overflow: scroll;
      flex-wrap: nowrap;
      gap: 1px;
      & video,
      & img {
        border-radius: 0;
        height: 100%;
      }
      & img {
        height: 100vh;
        max-height: 20rem;
        width: fit-content;
      }
      & .event-video-small {
        width: 50%;
        max-height: 20rem;
      }
      & .event-video-big {
        width: 100vw;
        max-height: auto;
      }
    }
    & .form-book {
      padding: 2rem 0;
      margin: 1rem 0;
      border: 0;
      border-top: 1px solid #eee;
      border-radius: 0;
      & .form-sign-up {
        min-height: auto;
      }
    }
  }

  .event-list {
    & .event-list-item {
      width: 100%;
      & .event-image {
        max-height: 20rem;
      }
    }
  }

  .intro {
    flex-direction: column;
    & div,
    & img {
      width: 100vw;
      height: auto;
    }
  }

  .app .profile {
    gap: 0rem;
    flex-direction: column;
    width: 100vw;
    & video {
      border-radius: 0;
      width: 100vw;
      height: auto;
    }
    & .profile-header {
      margin: 0;
      & .user-card {
        gap: 0.6rem;
        font-size: 1.2rem;
      }
    }
    & .profile-description {
      width: 100%;
      padding: 0rem;
      gap: 0rem;
    }
    & .profile-content {
      padding: 1.5rem;
    }
    & .avatar {
      border-radius: 0rem;
    }
  }

  .footer {
    padding: 2rem 1.5rem 6rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & .social {
      margin-top: 1rem;
    }

    & .footer-contact-details {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    & .select-box {
      justify-content: flex-start;
    }
    & .select-boxes {
      flex-direction: column;
      gap: 0.5rem;
      & select {
        width: 7rem;
      }
    }
    & .footer-sections {
      flex-direction: column;
      padding: 0;
      gap: 1.5rem;
      & span {
        text-align: left;
      }
    }
    & .footer-section-links {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
    & .footer-content {
      flex-direction: column;
    }
    & .footer-section-full {
      margin: 1.5rem 0 0;
      padding: 0;
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-start;
      align-items: flex-start;
    }
    & .links {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      & a {
        padding: 0.5rem 0rem;
      }
    }
    & .social-icon {
      font-size: 2rem;
    }
  }

  .page-content {
    padding: 1rem;
    width: 100%;
    min-height: 50vh;
    border-top: 1px solid #eee;
  }

  .tickets-page {
    padding: 0;
    width: 100%;
    min-height: 50vh;
    & .h3 {
      padding: 0 1rem;
    }
  }

  .tickets-list {
    flex-direction: column;
    margin: 1rem;
    padding: 0;
    width: auto;
    & .ticket {
      border: none;
      border: 1px solid #ccc;
      padding: 1rem;
      padding-bottom: 2rem;
      & .h3 {
        padding: 0;
      }
    }
    & .ticket-refunded {
      border: 1px solid #be1b1b;
    }
  }
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & .order {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid #ccc;
    border: 1px solid #ccc;
    padding: 0.5rem;
    padding-bottom: 1rem;
    overflow: hidden;
  }
  & .order-pending {
    border: 2px solid #d80000;
    padding: 0.5rem;
  }
}

.page-actions {
  display: flex;
  gap: 1rem;
  padding: 1rem 0 1.5rem;
  & .button {
    flex: 0;
  }
}

.my-events-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & .event-details {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    padding: 2rem;
    border: 1px solid #ccc;
    & .event-details-link {
      font-weight: 500;
      font-size: 1.3rem;
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    & .event-avatar {
      width: 100%;
      max-width: 10rem;
      border-radius: 0.5rem;
    }
  }
}

.button-danger {
  color: #333;
  border: 1px solid #ccc;
}

.form-event-slots {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .form-event-slot {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    border-bottom: 1px solid #ccc;
    padding: 1rem 0;
    & .event-slot-data {
      width: 14rem;
    }
    & .tickets-count {
      width: 7rem;
    }
    & .actions {
      display: flex;
      gap: 1rem;
    }
    & .button-action {
      padding: 0.7rem;
      border-radius: 0.3rem;
      border: 1px solid #ccc;
      color: #333;
      background: transparent;
      cursor: pointer;
      font-size: 1rem;
    }
  }
}

.page-filters {
  display: flex;
  gap: 0.7rem;
  padding: 1rem 0;
  align-items: center;
}

.page-filters-panel {
  display: flex;
  gap: 0.7rem;
  padding: 0;
  align-items: center;
}

.input-datepicker-container {
  xpadding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  box-shadow: 1px 1px 4px #eee;
  background: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & input,
  & .custom-input {
    padding: 1rem;
    width: 10rem;
    border: 0px;
    cursor: pointer;
    xtext-align: center;
    font-size: 1rem;
    xfont-weight: 600;
    background: transparent;
  }
  & input:focus {
    outline: none;
  }
}

.search-container {
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  background: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  outline: none;
  & svg {
    width: 1rem;
    height: 1rem;
    fill: #aaa;
  }
  & .button-small {
    border: none;
    color: #333;
  }
}
.search-input {
  border: none;
  font-size: 1rem;
  padding: 0.7rem 0.5rem;
  outline: none;
}

.button-new-slot {
  margin-top: 1rem;
  width: fit-content;
}

.event-slot-actions {
  display: flex;
  gap: 1rem;
  & .button-primary {
    flex: 1;
  }
}

.user-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  text-decoration: none;
  & img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    xborder-radius: 0.3rem;
    object-fit: cover;
  }
  & .video-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
  }
  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 3.5rem;
    object-fit: cover;
  }
  & xvideo {
    width: 1.12rem;
    height: 2rem;
    object-fit: cover;
    border-radius: 0.2rem;
  }
}

.user-card-medium {
  gap: 1rem;
  & img {
    width: 3rem;
    height: 3rem;
  }
  & .user-card-details {
    gap: 0.3rem;
    flex-direction: column;
    display: flex;
    justify-content: start;
    align-items: start;
  }
}

.user-row-banned {
  & td {
    background: #ccc;
  }
}

.user-row-admin {
  & td {
    background: #f88181;
  }
}

.user-row-supplier {
  & td {
    background: #def8ff;
  }
}

.text-green {
  color: #009111;
}

.text-red {
  color: #640000;
}
